<template>
  <transition
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
    :duration="100"
  >
    <v-sheet class="my-overlay" v-if="overlay">
      <v-row align="center" justify="center">
        <v-col align="center">
          <v-img
            src="@/assets/images/inndot.png"
            width="80"
            class="mb-4 animate__animated animate__heartBeat animate__infinite"
          ></v-img>
          <v-sheet width="150">
            <v-progress-linear indeterminate rounded color="primary"></v-progress-linear>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["overlay"]),
  },
};
</script>

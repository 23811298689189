import axios from 'axios';
import store from '../store/index'
import router from '../router/index'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(
  (request) => {
    // do something with request meta data, configuration, etc
    if(store.getters['account/userToken'] != null) {
      request.headers.common.token = store.getters['account/userToken'];
    }
    request.config = {
      ...(request.config ?? {}), // preserve a given request.config object
    };
    if (request.config.showOverlay == true) {
      store.commit('showOverlay')
    }
    return request;
  }
);

// doing something with the response
axios.interceptors.response.use(
  (response) => {
    // console.log(response);
    store.commit('hideOverlay')
    return response;
  },
  (error) => {
    store.commit('hideOverlay')

    let msg = (typeof error.response.data === "object" && "message" in error.response.data) ? error.response.data.message : error.response.data;
    msg = (msg === 'Invalid login details') ? 'Datos de inicio de sesión incorrectos' : msg;

    switch (error.response.status) {
      case 401:
        store.commit("showSnackBar", {
          text: msg,
          color: 'warning',
          icon: "mdi-alert"
        });
        store.commit('account/logOut');
        router.push('login').catch(()=>{});
        // return error;
        break;
      case 500:
        store.commit("showSnackBar", {
          text: 'Ocurrió un error en la petición, intenta nuevamente',
          color: 'error',
          icon: "mdi-alert-circle"
        });
        // return error;
        break;
    }
    
    return Promise.reject(error);
  }
);

export default axios;
<template>
  <!-- src="@/assets/images/background.jpg" -->
  <div v-if="isLoggedIn">
    <v-app-bar dense height="70px" app>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/images/inndot.png"
        max-height="40"
        max-width="40"
        class="mr-2"
      ></v-img>
      <v-toolbar-title>Gestor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="configDialog = true">
        <v-icon>mdi-tune</v-icon>
      </v-btn>
      <v-btn icon @click="$store.commit('account/toggleDarkTheme')">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
      <v-btn icon @click="$store.commit('account/logOut')">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer mini-variant v-model="drawer" app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar color="primary">
            <img src="@/assets/images/inndot.jpg" />
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-facebook</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Facebook</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/instagram">
          <v-list-item-icon>
            <v-icon>mdi-instagram</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Instagram</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/metricas">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Metricas</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/discovery">
          <v-list-item-icon>
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Discovery</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/medios">
          <v-list-item-icon>
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Medios</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item link to="/login">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>

    <!-- <v-footer app>
      <v-col class="text-center">
        {{ new Date().getFullYear() }} — <strong>Inndot</strong>
      </v-col>
    </v-footer> -->
    <v-dialog v-model="configDialog" width="700">
      <v-card>
        <v-card-title>
          Webhook
          <v-spacer></v-spacer>
          <v-btn icon @click="closeConfigDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="webhooktab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab><v-icon>mdi-facebook</v-icon> Facebook</v-tab>
            <v-tab><v-icon>mdi-instagram</v-icon> Instagram</v-tab>
          </v-tabs>
          <v-tabs-items v-model="webhooktab" class="mt-6 mb-6">
            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="primary"
                      @click="confirmDialog = true"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Instalar webhook
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="primary"
                      @click="confirmDialog = true"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Suscribir
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="confirmDialog = true"
                      :loading="isLoading"
                      :disabled="isLoading"
                      class="ml-3"
                    >
                      Instalar webhook
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="400px">
      <v-card>
        <v-card-title class="text-center">
          <h5>¿Seguro que desea instalar el webhook?</h5>
        </v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="secondary" @click="confirmDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            small
            color="primary"
            @click="installWebhook()"
            :loading="isLoading"
            :disabled="isLoading"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      drawer: true,
      configDialog: false,
      buttonLoading: false,
      confirmDialog: false,
      webhooktab: null,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    closeConfigDialog() {
      this.configDialog = false;
    },
    installWebhook() {
      this.buttonLoading = true;
      this.confirmDialog = false;

      axios
        .post("public/intallwebhook")
        .then((response) => {
          this.buttonLoading = false;
          this.$store.commit("showSnackBar", {
            text:
              typeof response.data === "object" && "message" in response.data
                ? response.data.message
                : response.data,
            color: "success",
            icon: "mdi-check-circle",
          });
          this.closeConfigDialog();
        })
        .catch(() => {
          // console.log(error.response.data);
          this.buttonLoading = false;
          this.closeConfigDialog();
        });
    },
  },
  computed: {
    ...mapState("account", ["isLoggedIn"]),
    isLoading() {
      return this.buttonLoading;
    },
  },
  watch: {
    async isLoggedIn(state) {
      if (!state) {
        this.$router.push({ name: "login" });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.state.account.isLoggedIn) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>

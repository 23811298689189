<template>
  <div class="info-absolute">
    <v-row>
      <v-col class="d-flex">
        <v-spacer></v-spacer>
        <v-alert
          v-model="showAlert"
          border="bottom"
          close-text="Cerrar"
          icon="mdi-information-outline"
          elevation="6"
          dismissible
          colored-border
          type="warning"
        >
          Sobre las recomendaciones
          <v-btn color="primary" class="ml-4" @click="showInfo = true">Leer más</v-btn>
        </v-alert>
        <v-btn elevation="6" fab @click="showInfo = true" class="mb-4" v-if="!showAlert">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="showInfo"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-icon large class="mr-2">mdi-information-outline</v-icon>Sobre las recomendaciones
        </v-card-title>

        <v-card-text class="text-justify">
          <p>Este sistema de gestión utiliza un algoritmo de clasificación de preguntas que, con base en los comentarios que las personas han realizado a esta cuenta, te ofrece opciones de respuestas frecuentes tomadas de la base de respuestas oficial.</p>
          <p>Al confirmar que la respuesta es correcta, ayudarás a reforzar el entrenamiento de este modelo de respuestas y el sistema sugerirá esa respuesta como correcta más veces para ese tipo de pregunta. Si, de acuerdo con tu criterio, la respuesta es mejorable o incorrecta, cuando corrijas la respuesta y la envíes al usuario a través del gestor estarás ayudando a mejorar el entrenamiento de las sugerencias y en el futuro te ofrecerá resultados mejores, más parecidos a lo que tú contestarías.</p>
          <p>Esto te puede ahorrar tiempo en responder y mejorar la cantidad y calidad de las respuestas que ofreces.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showInfo = false"
          >
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showAlert: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showInfo: false
    }
  }
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'facebook',
    component: () => import(/* webpackChunkName: "main" */ '../views/Facebook.vue')
  },
  {
    path: '/instagram',
    name: 'instagram',
    component: () => import(/* webpackChunkName: "posts" */ '../views/Instagram.vue')
  },
  {
    path: '/metricas',
    name: 'metricas',
    component: () => import(/* webpackChunkName: "metricas" */ '../views/Metricas.vue')
  },
  {
    path: '/discovery',
    name: 'discovery',
    component: () => import(/* webpackChunkName: "discovery" */ '../views/Discovery.vue')
  },
  {
    path: '/medios',
    name: 'medios',
    component: () => import(/* webpackChunkName: "medios" */ '../views/Medios.vue')
  },
  {
    path: '/fuente/:nombre',
    name: 'fuente',
    props: true,
    component: () => import(/* webpackChunkName: "fuente" */ '../views/Fuente.vue')
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

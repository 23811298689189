import Vue from 'vue'
import Vuex from 'vuex'
import account from './account'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showSearch: false,
    overlay: false,
    fbReplyDialog: false,
    snackbar: {
      visible: false,
      color: "success",
      text: null,
      icon: null,
      timeout: 3000,
      multiline: false
    },
    candidato: "SAT",
    tema: "",
    searching: false
  },
  mutations: {
    toggleSearch(state) {
      state.showSearch = !state.showSearch
    },
    closeSearch(state) {
      state.showSearch = false
    },
    showOverlay(state){
      state.overlay = true
    },
    hideOverlay(state){
      state.overlay = false
    },
    showFBReplyDialog(state) {
      state.fbReplyDialog = true;
    },
    hideFBReplyDialog(state) {
      state.fbReplyDialog = false;
    },
    showSnackBar(state, snackbarConfig) {
      state.snackbar.visible = true;
      ('color' in snackbarConfig) ? state.snackbar.color = snackbarConfig.color : false;
      ('text' in snackbarConfig) ? state.snackbar.text = snackbarConfig.text : false;
      ('timeout' in snackbarConfig) ? state.snackbar.timeout = snackbarConfig.timeout : false;
      ('text' in snackbarConfig) && snackbarConfig.text.length > 50 ? state.snackbar.multiline = snackbarConfig.multiline : false;
      ('icon' in snackbarConfig) ? state.snackbar.icon = snackbarConfig.icon : false;
    },
    closeSnackBar(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
      state.snackbar.icon = null;
    },
    setCandidato(state, candidato) {
      state.candidato = candidato
    },
    setTema(state, tema) {
      state.tema = tema
    },
    startSearch(state){
      state.searching = !state.searching
    },
  },
  actions: {
  },
  modules: {
    account
  },
  plugins: [createPersistedState({
    paths: ['account.darkTheme', 'account.searchWord', 'account.searchResults', 'account.sourceResults', 'account.isLoggedIn', 'account.userToken'],
  })]
})

export default {
  namespaced: true,
  state: {
    darkTheme: false,
    searchWord: 'chofer',
    searchResults: null,
    sourceResults: null,
    isLoggedIn: false,
    userToken: null,
    charts: null
  },
  mutations: {
    toggleDarkTheme(state) {
      state.darkTheme = !state.darkTheme;
    },
    setSearchWord(state, searchWord) {
      state.searchWord = searchWord;
    },
    setResult(state, search) {
      state.searchResults = search;
    },
    setSourceResults(state, results) {
      state.sourceResults = results;
    },
    logIn(state, token) {
      state.isLoggedIn = true;
      state.userToken = token;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.userToken = null;
    },
    setCharts(state, charts) {
      state.charts = charts;
    }
  },
  getters: {
    userToken(state) {
      return state.userToken;
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'animate.css'
import ScrollFadeIn from './directives/scrollfadein'
import VueAxios from 'vue-axios'
import axios from './plugins/axios'
import dateformat from 'dateformat'
// import {json2excel} from "js2excel";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import '@/assets/scss/custom.scss'

Vue.directive('scrollfadein', ScrollFadeIn)
Vue.use(VueAxios, axios)
Vue.component('vue-custom-scrollbar', vueCustomScrollbar)

Vue.config.productionTip = true

new Vue({
  router,
  store,
  vuetify,
  dateformat,
  // json2excel,
  render: h => h(App)
}).$mount('#app')
